<template>
    <div class="page view slot-client" style="overflow: hidden;">

        <div class="loading-screen" v-if="loading">
            <h5>Please wait</h5>
            <p>{{ loading_status }}</p>
        </div>

        <div class="container-game">
            <!-- Window Container -->
            <div class="window">
                <div class="overlay"></div>
                <!-- Main View -->
                <div class="content">
                    <!-- Top Navigation -->
                    <div class="vdo-streams" ref='videoStream'>
                        <div class='vdo-container row no-gutters' ref='videoContainer'>
                            <div class='main vdo-main col-lg-8 col-md-12' ref='mainVideo'>
                                <div class='stream_1' v-if='machine'>
                                    <!-- <video id="videoStream" ref="video_1" class="videoStream video_1" playsinline autoplay muted></video> -->
                                    <view-stream v-if="activeMobileStream == 'machine'" @touchStart="touchStart" :touchEnabled="true" addclass="video_1" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Game').code" class="w-100" />
                                    <view-stream  v-if="machine.videoStreams.find(e => e.videoStreamType.code == 'Jackpot') && activeMobileStream == 'jackpot'" class="w-100" addclass="video_2" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Jackpot').code" />
                                    <view-stream  v-if="machine.videoStreams.find(e => e.videoStreamType.code == 'Machine') && activeMobileStream == 'floor'" class="w-100" addclass="video_3" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Machine').code" />
                                </div>
                            </div>
                            <div class="auxiliary col-lg-4 col-md-12 w-100">
                                <div class='vdo-jackpot d-none d-sm-block' ref='secondVideo'>
                                    <div class='stream_2' v-if='machine'>
                                        <!-- <img class='side-videoStream video_2' ref='video_2' src='/images/16-9.png'> -->
                                        <view-stream v-if="machine.videoStreams.find(e => e.videoStreamType.code == 'Jackpot')" class="w-100" addclass="video_2" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Jackpot').code" />
                                    </div>
                                </div>
                                <div class='vdo-floor' ref='thirdVideo'>
                                    <div class='stream_3' v-if='machine'>
                                        <view-stream v-if="machine.videoStreams.find(e => e.videoStreamType.code == 'Machine')" class="w-100" addclass="video_3" :code="machine.videoStreams.find(e => e.videoStreamType.code == 'Machine').code" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 
                        Desktop Navigation 
                    -->
                    <div class="controls-desktop d-none d-sm-none d-md-none d-lg-block">
                        <!-- Bet -->
                        <div class="new-controls bet" v-if="keyMode == 'bet'">
                            <div class="left">
                                <slot-button type="full" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                            </div>
                            <div class="center">
                                <slot-button type="full" @click="sendCommandTest('PLAYBET1')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET', '1']" />
                                <slot-button type="full" @click="sendCommandTest('PLAYBET2')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET', '2']" />
                                <slot-button type="full" @click="sendCommandTest('PLAYBET3')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET', '3']" />
                                <slot-button type="full" @click="sendCommandTest('PLAYBET4')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET', '4']" />
                                <slot-button type="full" @click="sendCommandTest('PLAYBET5')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET', '5']" />
                            </div>
                        </div>
                        <!-- Lines -->
                        <div class="new-controls lines" v-if="keyMode == 'lines'">
                            <div class="left">
                                <slot-button type="full" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                            </div>
                            <div class="center">
                                <slot-button type="full" @click="sendCommandTest('LINES1')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES', '1']" />
                                <slot-button type="full" @click="sendCommandTest('LINES2')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES', '2']" />
                                <slot-button type="full" @click="sendCommandTest('LINES3')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES', '3']" />
                                <slot-button type="full" @click="sendCommandTest('LINES4')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES', '4']" />
                                <slot-button type="full" @click="sendCommandTest('LINES5')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES', '5']" />
                            </div>
                        </div>
                        <!-- Play -->
                        <div class="new-controls play" v-if="keyMode == 'play'">
                            <div class="left">
                                <slot-button type="full" @click="clickInsert" divClass="new-button" bgClass="button-bg bg_green" :labels="['INSERT']" />
                                <slot-button type="full" @click="sendCommandTest('PAY_OUT')" divClass="new-button" bgClass="button-bg bg_red" :labels="['COLLECT']" />
                                <div class="new-button-group">
                                    <slot-button type="half" @click="clickLobby" divClass="new-button simple_pink" bgClass="button-bg bg_pink" :labels="['LOBBY']" /><br>
                                    <slot-button style="margin-top: 10px;" type="half" @click="sendCommandTest('SELECT_GAME')" divClass="new-button simple_pink" bgClass="button-bg bg_purple" :labels="['GAMES']" />
                                </div>
                            </div>
                            <div class="center">
                                <slot-button type="full" @click="sendCommandTest('AUTO_PLAY')" divClass="new-button" bgClass="button-bg bg_blue" :labels="['AUTO', 'PLAY']" />
                                <slot-button type="full" @click="clickBet" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET']" />
                                <slot-button type="full" @click="clickLines" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES']" />
                                <!-- <slot-button type="full" @click="sendCommandTest(4)" divClass="new-button" bgClass="button-bg bg_black" :labels="['MAX']" /> -->
                                <slot-button type="full" @click="sendCommandTest('START')" divClass="new-button" bgClass="button-bg bg_green" :labels="['START']" />
                            </div>
                            <div class="right">
                                <slot-button type="quarter"  divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="camera" /> <br>
                                <slot-button type="quarter"  divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="mute" />
                            </div>
                        </div>
                    </div>




                    <!-- Portrait Mobile -->
                    <div class="controls-mobile-portrait d-block d-sm-none">
                        <div class="top">
                            <slot-button type="half" @click="clickLobby" divClass="new-button simple_pink" bgClass="button-bg bg_pink" :labels="['LOBBY']" />
                            <slot-button type="quarter"  divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="camera" />
                            <slot-button type="quarter"  divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="mute" />
                            <slot-button type="half" @click="clickInsert" divClass="new-button" bgClass="button-bg bg_green" :labels="['INSERT']" />
                        </div>
                        <div class="bottom" v-if="keyMode == 'play'">
                            <slot-button type="half" @click="sendCommandTest('SELECT_GAME')" divClass="new-button simple_pink" bgClass="button-bg bg_purple" :labels="['GAMES']" />
                            <slot-button type="quarter" @click="clickBet" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET']" style="position: relative; left: 0px;" />
                            <!-- <slot-button type="quarter" @click="sendCommandTest(4)" divClass="new-button" bgClass="button-bg bg_black" :labels="['MAX']" style="position: relative; left: -5px;" /> --><br>
                            <slot-button type="half" @click="sendCommandTest('AUTO_PLAY')" divClass="new-button" bgClass="button-bg bg_blue" :labels="['AUTOPLAY']" />
                            <slot-button type="half" @click="clickLines" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES']" style="position: relative; left: 5px;" />
                            <slot-button style="position: fixed; bottom: 12px; right: 0px; z-index: 99;"type="full" @click="sendCommandTest('START')" divClass="new-button" bgClass="button-bg bg_green" :labels="['START']" />
                        </div>

                        <div class="bottom" v-if="keyMode == 'lines'">
                            <slot-button type="half" @click="sendCommandTest('LINES1')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES1']" />
                            <slot-button type="half" @click="sendCommandTest('LINES2')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES 2']" />
                            <slot-button type="half" @click="sendCommandTest('LINES3')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES 3']" />
                            <slot-button type="half" @click="sendCommandTest('LINES4')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES 4']" />
                            <slot-button type="half" @click="sendCommandTest('LINES5')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES 5']" />
                            <slot-button type="half" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                        </div>

                        <div class="bottom" v-if="keyMode == 'bet'">
                            <slot-button type="half" @click="sendCommandTest('PLAYBET1')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET 1']" />
                            <slot-button type="half" @click="sendCommandTest('PLAYBET2')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET 2']" />
                            <slot-button type="half" @click="sendCommandTest('PLAYBET3')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET 3']" />
                            <slot-button type="half" @click="sendCommandTest('PLAYBET4')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET 4']" />
                            <slot-button type="half" @click="sendCommandTest('PLAYBET5')" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET 5']" />
                            <slot-button type="half" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                        </div>
                    </div>
                    <!-- 
                        Landscape Mobile 
                    -->
                    <div class="controls-mobile-landscape d-none d-sm-block d-md-block d-lg-none">
                        <div class="left" v-if="keyMode == 'play'">
                            <slot-button type="half" @click="clickLobby" divClass="new-button simple_pink" bgClass="button-bg bg_pink" :labels="['LOBBY']" />
                            <slot-button type="quarter" @click="clickBet" divClass="new-button" bgClass="button-bg bg_red" :labels="['BET']" />
                            <!-- <slot-button type="quarter" @click="sendCommandTest(9)" divClass="new-button" bgClass="button-bg bg_black" :labels="['MAX']" /> -->
                            <slot-button type="half" @click="clickLines" divClass="new-button" bgClass="button-bg bg_orange" :labels="['LINES']" style="position: relative; top: -5px;" />
                            <slot-button style="margin-top: 10px;" type="half" @click="sendCommandTest('SELECT_GAME')" divClass="new-button simple_pink" bgClass="button-bg bg_purple" :labels="['GAMES']" />
                            <slot-button style="position: fixed; bottom: 5px; z-index: 99;" type="half" @click="sendCommandTest('AUTO_PLAY')" divClass="new-button" bgClass="button-bg bg_blue" :labels="['AUTOPLAY']" />
                        </div>
                        <div class="left" v-if="keyMode == 'lines'">
                            <slot-button type="quarter" @click="sendCommandTest('LINES1')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['L 1']" />
                            <slot-button type="quarter" @click="sendCommandTest('LINES2')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['L 2']" /><br>
                            <slot-button type="quarter" @click="sendCommandTest('LINES3')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['L 3']" />
                            <slot-button type="quarter" @click="sendCommandTest('LINES4')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['L 4']" /><br>
                            <slot-button type="quarter" @click="sendCommandTest('LINES5')" divClass="new-button" bgClass="button-bg bg_orange" :labels="['L 5']" />
                            <slot-button style="position: fixed; bottom: 5px; z-index: 99;" type="half" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                        </div>
                        <div class="left" v-if="keyMode == 'bet'">
                            <slot-button type="quarter" @click="sendCommandTest('PLAYBET1')" divClass="new-button" bgClass="button-bg bg_red" :labels="['B 1']" />
                            <slot-button type="quarter" @click="sendCommandTest('PLAYBET2')" divClass="new-button" bgClass="button-bg bg_red" :labels="['B 2']" /><br>
                            <slot-button type="quarter" @click="sendCommandTest('PLAYBET3')" divClass="new-button" bgClass="button-bg bg_red" :labels="['B 3']" />
                            <slot-button type="quarter" @click="sendCommandTest('PLAYBET4')" divClass="new-button" bgClass="button-bg bg_red" :labels="['B 4']" /><br>
                            <slot-button type="quarter" @click="sendCommandTest('PLAYBET5')" divClass="new-button" bgClass="button-bg bg_red" :labels="['B 5']" />
                            <slot-button style="position: fixed; bottom: 5px; z-index: 99;" type="half" @click="clickBack" divClass="new-button" bgClass="button-bg bg_black" :labels="['BACK']" />
                        </div>
                        <div class="right">
                            <slot-button type="half" @click="clickInsert" divClass="new-button simple_pink" bgClass="button-bg bg_green" :labels="['INSERT']" />
                            <slot-button type="quarter" @click="toggleMobileStream" divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="camera" />
                            <slot-button type="quarter" divClass="new-button" bgClass="button-bg bg_white" :labels="['']" icon="mute" />
                            <slot-button style="position: fixed; bottom: -5px; z-index: 99;" type="full" @click="sendCommandTest('START')" divClass="new-button" bgClass="button-bg bg_green" :labels="['START']" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="castVideoModal" tabindex="-1" role="dialog" aria-labelledby="castVideoModal" aria-hidden="true" v-if="session">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="castVideoModal">Cast Video</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="castVideoModalActive">
                        <cast-video-code />
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="overlays">
            <div class="row loadMoney overlay  justify-content-md-center align-content-center" v-if="showInsert" data-aos="fade-in" style="height: calc(100vh + 50px)">
                <div class="box col-xs-2" data-aos="fade-down">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text">
                            CURRENT AMOUNT <div class="pull-right">{{ balance }} RON</div>
                        </div>
                    </div>
                    <div class="row chips justify-content-md-center">
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(0.01)">
                                <div class="image">
                                    <img src="@/assets/images/chips/yellow.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        0.01
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(10)">
                                <div class="image">
                                    <img src="@/assets/images/chips/pink.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        10
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(100)">
                                <div class="image">
                                    <img src="@/assets/images/chips/blue.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        100
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="insert(500)">
                                <div class="image">
                                    <img src="@/assets/images/chips/red.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        500
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip all" @click="insert(balance)">
                                <div class="image">
                                    <img src="@/assets/images/chips/all.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        ALL
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="showInsert = false" type="button" class="btn btn-secondary form-control">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.move_me_to_css_after_merge {}

.remote_connected {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 50px;
}

.cast {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 9999;
}
</style>
<script>
import $ from 'jquery';
import ViewStream from './components/ViewStream.vue';
import QRCode from './components/QRCode.vue';
import CastVideoCode from './components/CastVideoCode.vue';
import SlotButton from './Button.vue';

export default {
    data() {
        return {
            activeMobileStream: 'machine',
            streamTypes: ['machine', 'floor', 'jackpot'],
            balance: null,
            balanceCurrency: null,
            showInsert: false,
            keyMode: 'play',
            keys_blocked: false,

            loading: false,
            loading_status: '',

            connected: false,
            remote_connected: false,

            machine: null,
            session: null,

            active_stream: null,
            streams: [],

            peer_connection: null,
            force_turn: false,
            video_div: null,
            webrtc_config: {
                iceServers: [
                    { urls: 'stun:stun.cloud.ro:5349' },
                    {
                        urls: 'turn:turn.cloud.ro:5349',
                        username: 'turnuser',
                        credential: 'letmein123',
                    }
                ]
            },

            multiview: true,
            offlineStreams: null,
            isMaxMobileDim: false,
            viewData: null,

            // CastVideo
            castVideoModalActive: false,


            showAddFunds: false,
            showRemoveFunds: false,
            transfer_amount: 50,



        }
    },
    components: {
        'view-stream': ViewStream,
        'qr-code': QRCode,
        'cast-video-code': CastVideoCode,
        'slot-button': SlotButton,
    },
    beforeDestroy() { 
        //this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);
    },
    async mounted() {


        this.$root.$emit('changeViewType', 'simple');

        var self = this;

        $("html, body").animate({ scrollTop: 0 }, 0);

        // Load Video Div in Data.
        this.video_div = document.getElementById('videoStream');

        // Grab machien details and connect to
        // the according primary stream
        await this.getMachineDetails();

        // Emit StartPlay event
        this.$socket.emit('start_play', this.machine.id, this.session.distributedId, this.$socket.id);
        this.$root.$emit('slotClientData', this.machine);
        this.$root.$on('streamMultiView', this.changeMultiView);
        this.$root.$on('changeViewType', this.changeViewType);
        this.$root.$on('clickCastVideo', this.clickCastVideo);

        this.$root.$on('clickAddFunds', () => {
            this.transfer_amount = 50;
            this.showRemoveFunds = false;
            this.showAddFunds = true;
        });

        this.$root.$on('clickRemoveFunds', () => {
            this.transfer_amount = 50;
            this.showAddFunds = false;
            this.showRemoveFunds = true;
        });

        // CastVideoModal Binding
        $("#castVideoModal").on("hidden.bs.modal", function() {
            self.castVideoModalActive = false;
            self.$forceUpdate();
        });
        $("#castVideoModal").on("show.bs.modal", function() {
            self.castVideoModalActive = true;
            self.$forceUpdate();
        });

    },
    beforeDestroy() { 
        this.closeConnection();
        this.$socket.emit('pause_play', this.machine.id, this.session.distributed_id, this.$socket.id);
    },
    sockets: {
        closedSession: function() {
            this.$router.push('/');
        },
        startLoading: function(status) {
            console.log('startLoading');
            this.loading = true;
            this.loading_status = status;
        },
        stopLoading: function() {
            console.log('stopLoading');   
            this.loading = false;
        },
        session_message: function(message) {
   
            console.log(message);

            if (message.session_id === this.session.id) {
                if (message.type === 'success') {
                    this.$toasted.success(message.message);
                } else if (message.type === 'error') {
                    this.$toasted.error(message.message);
                }
            }

        },

        remote_play_stopped: function(session_id) {
            console.log('REMOTE PLAY STOPPED');
            this.remote_connected = false;
        },

        remote_play_started: function(session_id) {
            console.log('REMOTE PLAY STARTED');
            this.remote_connected = true;
            $("#castKeysModal").modal('hide');

        },


    },
    watch: {
        remote_connected: function() {
            if (this.remote_connected) {
                $('.layout').hide();

                if (!this.isMaxMobileDim) {
                    this.streamSize(true);
                } else {
                    this.streamSizeMobile(true);
                }

                //$('.controls').css({height:'50px'});
            } else {
                //$('.controls').css({height:'auto'});
                console.log('show');
                $('.layout').show();
                console.log('show');

                this.initLayout();
                if (!this.isMaxMobileDim) {
                    this.streamSize(false);
                } else {
                    this.streamSizeMobile(false);
                }
            }
        }
    },
    methods: {
        lookupKey(action) {
            let buttonCode = this.machine.buttonCodes.find(e => e.action == action);
            if(!buttonCode) {
                this.$toasted.error('Button code missing');
                return;
            }
            return buttonCode.code;
        },
        async toggleMobileStream() {
            return;
            let currentIndex =  this.streamTypes.indexOf(this.activeMobileStream);
            this.activeMobileStream = null;
            await this.$nextTick();
            this.activeMobileStream = (currentIndex < this.streamTypes.length - 1) ? this.streamTypes[currentIndex + 1] : this.streamTypes[0];
            console.log(this.activeMobileStream);
        },
        async insert(amount) {
            this.showInsert = false;
            this.loading = true;
            await setTimeout(() => {}, 100);
            this.$http.post('https://signal-ct.cloud.ro/api/sessions/addFunds', {
                    session_id: this.$route.params.id,
                    amount: amount,
                    socket_id: this.$socket.id,
                })
                .then(res => {
                    this.loading = false;
                    this.showAddFunds = false;
                    if (res.data.success) {
                        this.$toasted.success('Credit added');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }
                    this.showInsert = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                    this.showInsert = false;
                });
        },
        async getBalance() {
            //let response = await this.$http.get('players/profile');
            //this.balance = (response.data.balance.amount / 100).toFixed(2);
            let response = await this.$http.get('players/remoteDetails');
            this.balance = response.data.account.balance;
        },
        async clickInsert() {
            await this.getBalance();
            this.showInsert = true;
        },
        clickLobby() {
            this.$router.push('/platform');
        },
        clickLines() {
            this.keyMode = 'lines';
        },
        clickBet() {
            this.keyMode = 'bet';
        },
        clickBack() {
            this.keyMode = 'play';
        },
        removeFunds() {

            this.loading = true;
            this.$http.post('https://signal-ct.cloud.ro/api/sessions/removeFunds', {
                    session_id: this.session.id,
                    amount: this.transfer_amount,
                    socket_id: this.$socket.id,
                })
                .then(res => {

                    this.loading = false;
                    this.showAddFunds = false;
                    this.showRemoveFunds = false;

                    if (res.data.success) {
                        //this.$toasted.success('Funds removed');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }

                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });


        },


        addFunds() {

            this.loading = true;
            this.$http.post('https://signal-ct.cloud.ro/api/sessions/addFunds', {
                    session_id: this.$route.params.id,
                    amount: this.transfer_amount,
                    socket_id: this.$socket.id,
                })
                .then(res => {

                    this.loading = false;
                    this.showAddFunds = false;

                    if (res.data.success) {
                        //this.$toasted.success('Funds added');
                    } else {
                        this.$toasted.error(res.data.reason);
                    }

                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });

        },

        clickCastVideo() {
            $("#castVideoModal").modal('show');
        },
        initLayout() {

            return;

            var self = this;

            if (!this.machine.buttonLayout) {
                this.$toasted.error('No button layout on this machine');
                return;
            }

            if (!this.machine.buttonLayout.zones) {
                this.$toasted.error('No button zones associated in this button layout');
                return;
            }

            // Select elements
            let zone = this.machine.buttonLayout.zones[0];
            let layout = $(this.$refs.layout);
            console.log(zone);

            // Clear the layout
            layout.empty();

            // Calculate square size in px
            let squareSize = layout.width() / zone.width;




            // Set layout exact size
            //layout.width( zone.width * squareSize );
            layout.height(zone.height * squareSize + 50);

            // Generate 2d matrix of [x][y] (row/column)
            // possible positions
            /*let matrix = [];
            for(let x = 0; x < zone.width; x++) { 
                let row = [];
                for(let y = 0; y < zone.height; y++) {

                    let point = {
                        x: x,
                        y: y,
                        xPx: x * squareSize,
                        yPx: y * squareSize,
                    }
                    row[y] = point;

                    // Render testing blocks
                    
                    let box = $("<div class='box'></div>");

                    box.css('height', squareSize);
                    box.css('width', squareSize);
                    box.css('top', point.yPx);
                    box.css('left', point.xPx);

                    layout.append(box);

                    this.$forceUpdate();

                }
                matrix[x] = row;
            }*/

            let c = 0;

            zone.buttons.forEach(button => {

                let buttonWidth = button.width * squareSize;
                let buttonHeight = button.height * squareSize;
                let refSize;
                let buttonBackground;
                let buttonBg;
                let overlay;

                let div = $("<div class='layout_button'></div>");
                div.addClass(button.type.toLowerCase());

                if (div.hasClass('simple_white')) {
                    buttonBackground = 'bg_white';
                }
                if (div.hasClass('simple_green')) {
                    buttonBackground = 'bg_green';
                }
                if (div.hasClass('double_white_black')) {
                    buttonBackground = 'bg_whiteBlack';
                }

                if (buttonWidth < buttonHeight) {
                    refSize = buttonWidth;
                } else if (buttonWidth > buttonHeight) {
                    refSize = buttonHeight;
                } else {
                    refSize = buttonWidth;
                }

                //let div = $("<div class='layout_button'></div>");

                div.css('height', buttonHeight);
                div.css('width', buttonWidth);
                div.css('top', button.y * squareSize);
                div.css('left', button.x * squareSize);

                //div.addClass(button.type.toLowerCase());

                let outer = $("<div class='outer-boarder'></div>");
                outer.css({
                    'height': buttonHeight,
                    'width': buttonWidth,
                    'border-radius': 0.125 * refSize
                });


                let metallic = $("<div class='metalic-boarder'></div>");
                metallic.css({
                    'height': buttonHeight - 0.025 * refSize,
                    'width': buttonWidth - 0.025 * refSize,
                    'top': 0.025 * refSize / 2,
                    'left': 0.025 * refSize / 2,
                    'border-radius': 0.125 * refSize
                });


                let inner = $("<div class='inner-boarder'></div>");
                inner.css({
                    'height': buttonHeight - 0.125 * refSize,
                    'width': buttonWidth - 0.125 * refSize,
                    'top': 0.1 * refSize / 2,
                    'left': 0.1 * refSize / 2,
                    'border-radius': 0.0875 * refSize,
                });

                button.captions.sort(function(a, b) {
                    return a.lineNumber - b.lineNumber;
                });

                // Double caption
                if (button.captions.length == 2) {

                    let content1 = $("<span class='button-content-top'>" + button.captions[0].name + "</span>");
                    let content2 = $("<span class='button-content-bot'>" + button.captions[1].name + "</span>");


                    if (div.hasClass('simple_white') || div.hasClass('simple_green')) {

                        buttonBg = $("<div class='button-bg " + buttonBackground + "'></div>");
                        buttonBg.css({
                            'height': buttonHeight - 0.175 * refSize,
                            'width': buttonWidth - 0.175 * refSize,
                            'top': 0.05 * refSize / 2,
                            'left': 0.05 * refSize / 2,
                            'border-radius': 0.0625 * refSize,
                        });


                        overlay = $("<div class='button-overlay'></div>");
                        overlay.css({
                            'height': (buttonHeight - 0.25 * refSize) / 2 - 0.075 * refSize / 2,
                            'width': buttonWidth - 0.25 * refSize - 0.075 * refSize / 2,
                            'top': 0.075 * refSize / 2,
                            'left': 0.075 * refSize / 2,
                            'border-radius': 0.0375 * refSize,
                        });

                        buttonBg.append(content1);
                        buttonBg.append(content2);
                        buttonBg.append(overlay);
                        inner.append(buttonBg);
                        metallic.append(inner);
                        outer.append(metallic);
                        div.append(outer);

                        layout.append(div);


                        //console.log(content2.css("height"));
                        // content2.css({'top': (buttonHeight - 0.25*refSize)/2 - parseInt(content2.css("height"))});
                        // content1.css({'top': (buttonHeight - 0.25*refSize)/2 - parseInt(content1.css("height"))});

                        content2.css({ 'top': 0.18 * buttonHeight });
                        content1.css({ 'top': 0.18 * buttonHeight });



                    } else {

                        let buttonBgH1;
                        let buttonBgH2;

                        buttonBg = $("<div class='button-bg-border " + buttonBackground + "'></div>");
                        buttonBg.css({
                            'height': buttonHeight - 0.175 * refSize,
                            'width': buttonWidth - 0.175 * refSize,
                            'top': 0.05 * refSize / 2,
                            'left': 0.05 * refSize / 2,
                            'border-radius': 0.0625 * refSize,
                        });

                        buttonBgH1 = $("<div class='button-bg-half_1'></div>");
                        buttonBgH1.css({
                            'height': (buttonHeight - 0.175 * refSize) / 2 - 3,
                            'width': buttonWidth - 0.175 * refSize - 6,
                            'border-radius': +'0 0 ' + 0.0625 * refSize + ' ' + 0.0625 * refSize,
                        });

                        buttonBgH2 = $("<div class='button-bg-half_2'></div>");
                        buttonBgH2.css({
                            'height': (buttonHeight - 0.175 * refSize) / 2 - 3,
                            'width': buttonWidth - 0.175 * refSize - 6,
                            'border-radius': 0.0625 * refSize + ' ' + 0.0625 * refSize + ' 0 0',
                        });


                        overlay = $("<div class='button-overlay_2'></div>");
                        overlay.css({
                            'height': 0.7 * (buttonHeight - 0.25 * refSize),
                            'width': buttonWidth - 0.25 * refSize - 0.075 * refSize,
                            'top': 0.075 * refSize / 2,
                            'left': 0.075 * refSize / 2,
                            'border-radius': 0.0375 * refSize,
                        });


                        buttonBgH1.append(content1);
                        buttonBgH2.append(content2);
                        buttonBg.append(buttonBgH1);
                        buttonBg.append(buttonBgH2);
                        buttonBg.append(overlay);
                        inner.append(buttonBg);
                        metallic.append(inner);
                        outer.append(metallic);
                        div.append(outer);

                        layout.append(div);

                    }
                }

                // Single caption
                if (button.captions.length == 1) {

                    buttonBg = $("<div class='button-bg " + buttonBackground + "'></div>");
                    buttonBg.css({
                        'height': buttonHeight - 0.175 * refSize,
                        'width': buttonWidth - 0.175 * refSize,
                        'top': 0.05 * refSize / 2,
                        'left': 0.05 * refSize / 2,
                        'border-radius': 0.0625 * refSize,
                    });


                    overlay = $("<div class='button-overlay'></div>");
                    overlay.css({
                        'height': (buttonHeight - 0.25 * refSize) / 2 - 0.075 * refSize / 2,
                        'width': buttonWidth - 0.25 * refSize - 0.075 * refSize / 2,
                        'top': 0.075 * refSize / 2,
                        'left': 0.075 * refSize / 2,
                        'border-radius': 0.0375 * refSize,
                    });


                    let content = $("<span class='button-content'>" + button.captions[0].name + "</span>");

                    buttonBg.append(content);
                    buttonBg.append(overlay);
                    inner.append(buttonBg);
                    metallic.append(inner);
                    outer.append(metallic);
                    div.append(outer);

                    layout.append(div);

                }

                div.on('click', () => {
                    console.log('click');
                    self.sendCommandTest(button.code);
                });

                // layout.append(div);

            });

        },

        determineRefDim(width, height, aspectRation) {

            //Determines the maximum height/width of a video based on available screen space and video aspect ratio

            if (height * aspectRation <= width) {

                return { width: height * aspectRation, height: height }

            } else if (height * aspectRation > width) {

                return { width: width, height: width / aspectRation }

            }

        },

        setDimensions(remote) {

            return;
            let availableHeight;
            let layout = $(this.$refs.layout);

            let gameHeader = 50 // height in px of game header
            let windowHeight = $(window).height();
            let windowWidth = $(window).width();

            if (remote) {
                let controlsHeight = 50;
                availableHeight = windowHeight - controlsHeight - gameHeader;
            } else {
                availableHeight = windowHeight - layout.height() - gameHeader;
            }

            let mainVideo = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Game');
            let jackpot = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Jackpot');
            let floor = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Machine');


            let stream1AspectRatio = mainVideo.videoResolution.width / mainVideo.videoResolution.height;
            let stream2AspectRatio = jackpot.videoResolution.width / jackpot.videoResolution.height;
            let stream3AspectRatio = floor.videoResolution.width / floor.videoResolution.height;


            let videoContainer = $(this.$refs.videoContainer);
            let firstVideo = $(this.$refs.mainVideo);
            let secondVideo = $(this.$refs.secondVideo);
            let thirdVideo = $(this.$refs.thirdVideo);

            let video1 = $('.video_1');
            let stream1 = $('.stream_1');

            let video2, video3, stream2, stream3;

            if (this.multiview) {
                video2 = $('.video_2');
                video3 = $('.video_3');
                stream2 = $('.stream_2');
                stream3 = $('.stream_3');
            }

            this.viewData = {
                availableHeight: availableHeight,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                stream1AspectRatio: stream1AspectRatio,
                stream2AspectRatio: stream2AspectRatio,
                stream3AspectRatio: stream3AspectRatio,
                videoContainer: videoContainer,
                firstVideo: firstVideo,
                secondVideo: secondVideo,
                thirdVideo: thirdVideo,
                video1: video1,
                video2: video2,
                video3: video3,
                stream1: stream1,
                stream2: stream2,
                stream3: stream3,
            }
        },

        streamSizeMobile(isRemote) {

            return;

            this.setDimensions(isRemote);
            $(this.$refs.videoStream).css({ height: this.viewData.availableHeight });

            switch (this.offlineStreams.length) {
                case 0:
                    //Portrait
                    if ($(document).width() < $(document).height()) {
                        this.$root.$emit('toggleMiddleView', true)
                        this.isMaxMobileDim = true;
                        this.viewData.videoContainer.addClass('portraitMobileMax');
                        this.viewData.videoContainer.removeClass('landscapeMax');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth, (this.viewData.availableHeight - mainScreenDimensions.height) / 2, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    } else {

                        //Landscape
                        this.isMaxMobileDim = false;
                        this.viewData.videoContainer.addClass('landscapeMax');
                        this.viewData.videoContainer.removeClass('portraitMobileMax mainScreenTop mainScreenBottom');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio)
                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, 0.7 * this.viewData.availableHeight, this.viewData.stream1AspectRatio)
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth / 2, 0.3 * this.viewData.availableHeight, this.viewData.stream2AspectRatio)
                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    }
                    break;

                case 1:

                    if (this.offlineStreams[0].videoStreamType.code == 'Jackpot') {
                        this.viewData.secondVideo.css({ 'display': 'none' });
                    } else {
                        this.viewData.thirdVideo.css({ 'display': 'none' });
                    }

                    //Portrait
                    if ($(document).width() < $(document).height()) {
                        this.$root.$emit('toggleMiddleView', false)
                        this.isMaxMobileDim = true;
                        this.viewData.videoContainer.addClass('portraitMobile');
                        this.viewData.videoContainer.removeClass('landscape portrait');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth, (this.viewData.availableHeight - mainScreenDimensions.height), this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    } else {

                        //Landscape
                        this.isMaxMobileDim = false;
                        this.viewData.videoContainer.addClass('landscape');
                        this.viewData.videoContainer.removeClass('portraitMobile portrait mainScreenBottom');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, 0.7 * this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth / 2, 0.3 * this.viewData.availableHeight, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    }
                    break;
                default:

                    this.viewData.secondVideo.css({ 'display': 'none' });
                    this.viewData.thirdVideo.css({ 'display': 'none' });


                    //Portrait
                    if ($(document).width() < $(document).height()) {
                        this.isMaxMobileDim = true;
                        this.viewData.videoContainer.addClass('portraitMobile');
                        this.viewData.videoContainer.removeClass('landscape portrait');


                        let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                        this.viewData.video1.css(mainScreenDimensions);
                        this.viewData.stream1.css(mainScreenDimensions);
                    } else {

                        //Landscape
                        this.isMaxMobileDim = false;
                        this.viewData.videoContainer.addClass('landscape');
                        this.viewData.videoContainer.removeClass('portraitMobile portrait mainScreenBottom');


                        let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio)
                        this.viewData.video1.css(mainScreenDimensions);
                        this.viewData.stream1.css(mainScreenDimensions);

                    }
                    break;
            }
        },

        streamSize(isRemote) {

            return;
            this.setDimensions(isRemote);
            $(this.$refs.videoStream).css({ height: this.viewData.availableHeight });

            switch (this.offlineStreams.length) {
                case 0:
                    this.$root.$emit('toggleMiddleView', true)
                    //Lanscape
                    if ($(document).width() > $(document).height()) {
                        this.viewData.videoContainer.addClass('landscapeMax')
                        this.viewData.videoContainer.removeClass('portraitMax');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(0.7 * this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(0.3 * this.viewData.windowWidth, this.viewData.availableHeight / 2, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    } else {

                        //Portrait

                        this.viewData.videoContainer.addClass('portraitMax');
                        this.viewData.videoContainer.removeClass('landscapeMax');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, 0.7 * this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth / 2, 0.3 * this.viewData.availableHeight, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    }
                    break;
                case 1:
                    if (this.offlineStreams[0].videoStreamType.code == 'Jackpot') {
                        this.viewData.secondVideo.css({ 'display': 'none' });
                    } else {
                        this.viewData.thirdVideo.css({ 'display': 'none' });
                    }
                    this.$root.$emit('toggleMiddleView', false);
                    //Lanscape
                    if ($(document).width() > $(document).height()) {

                        this.viewData.videoContainer.addClass('landscape')
                        this.viewData.videoContainer.removeClass('portrait');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(0.7 * this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(0.3 * this.viewData.windowWidth, this.viewData.availableHeight / 2, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    } else {

                        //Portrait

                        this.viewData.videoContainer.addClass('portrait');
                        this.viewData.videoContainer.removeClass('landscape');

                        if (this.viewData.secondVideo.hasClass('notVisible')) {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.stream1.css(mainScreenDimensions);
                        } else {
                            let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, 0.7 * this.viewData.availableHeight, this.viewData.stream1AspectRatio);
                            let secondaryScreenDimensions = this.determineRefDim(this.viewData.windowWidth / 2, 0.3 * this.viewData.availableHeight, this.viewData.stream2AspectRatio);

                            this.viewData.video1.css(mainScreenDimensions);
                            this.viewData.video2.css(secondaryScreenDimensions);
                            this.viewData.video3.css(secondaryScreenDimensions);

                            this.viewData.stream1.css(mainScreenDimensions);
                            this.viewData.stream2.css(secondaryScreenDimensions);
                            this.viewData.stream3.css(secondaryScreenDimensions);
                        }
                    }
                    break;
                default:
                    this.viewData.secondVideo.css({ 'display': 'none' });
                    this.viewData.thirdVideo.css({ 'display': 'none' });
                    //Lanscape
                    if ($(document).width() > $(document).height()) {
                        this.viewData.videoContainer.addClass('landscape mainStream')
                        this.viewData.videoContainer.removeClass('portrait');

                        let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                        this.viewData.video1.css(mainScreenDimensions);
                        this.viewData.stream1.css(mainScreenDimensions);
                    } else {

                        //Portrait
                        this.viewData.videoContainer.addClass('portrait mainStream');
                        this.viewData.videoContainer.removeClass('landscape');

                        let mainScreenDimensions = this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio);

                        this.viewData.video1.css(mainScreenDimensions);
                        this.viewData.stream1.css(mainScreenDimensions);

                    }
                    break;
            }



        },

        changeMultiView() {

            return;

            this.setDimensions(this.remote_connected);


            if (this.viewData.secondVideo.hasClass('notVisible')) {
                this.viewData.secondVideo.removeClass('notVisible');
                this.viewData.thirdVideo.removeClass('notVisible');

                this.viewData.videoContainer.removeClass('mainStream');
                this.streamSize(this.remote_connected);

            } else {
                this.viewData.videoContainer.removeClass('mainScreenTop mainScreenBottom portraitMobile portraitMobileMax');

                this.viewData.secondVideo.addClass('notVisible');
                this.viewData.thirdVideo.addClass('notVisible');
                this.viewData.videoContainer.addClass('mainStream');

                this.viewData.video1.css(this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio));
                this.streamSize(this.remote_connected);
            }
        },

        changeViewType(position) {

            return;

            this.setDimensions(this.remote_connected);

            switch (position) {
                case 'Simple':
                    this.viewData.videoContainer.removeClass('mainScreenTop mainScreenBottom portraitMobile portraitMobileMax');

                    this.viewData.secondVideo.addClass('notVisible');
                    this.viewData.thirdVideo.addClass('notVisible');
                    this.viewData.videoContainer.addClass('mainStream');

                    this.viewData.video1.css(this.determineRefDim(this.viewData.windowWidth, this.viewData.availableHeight, this.viewData.stream1AspectRatio));
                    this.streamSize(this.remote_connected);
                    break;

                case 'Multi':
                    this.viewData.secondVideo.removeClass('notVisible');
                    this.viewData.thirdVideo.removeClass('notVisible');

                    this.viewData.videoContainer.removeClass('mainStream portraitMobile portraitMobileMax');
                    this.streamSize(this.remote_connected);
                    break;

                case 'Top':
                    if (this.offlineStreams.length == 0) {
                        this.viewData.videoContainer.removeClass('mainStream portraitMax mainScreenBottom');
                        this.viewData.secondVideo.removeClass('notVisible');
                        this.viewData.thirdVideo.removeClass('notVisible');

                        this.streamSizeMobile(this.remote_connected);
                        this.viewData.videoContainer.addClass('mainScreenTop');

                    } else if (this.offlineStreams.length == 1) {
                        this.viewData.videoContainer.removeClass('mainStream mainScreenBottom');
                        this.viewData.secondVideo.removeClass('notVisible');
                        this.viewData.thirdVideo.removeClass('notVisible');

                        this.streamSizeMobile(this.remote_connected);
                    }

                    break;

                case 'Middle':
                    this.viewData.videoContainer.removeClass('mainStream portraitMax mainScreenTop mainScreenBottom');
                    this.viewData.secondVideo.removeClass('notVisible');
                    this.viewData.thirdVideo.removeClass('notVisible');
                    this.streamSizeMobile(this.remote_connected);

                    break;

                case 'Bottom':
                    if (this.offlineStreams.length == 0) {
                        this.viewData.videoContainer.removeClass('mainStream portraitMax mainScreenTop');
                        this.viewData.secondVideo.removeClass('notVisible');
                        this.viewData.thirdVideo.removeClass('notVisible');
                        this.streamSizeMobile(this.remote_connected);
                        this.viewData.videoContainer.addClass('mainScreenBottom');

                    } else if (this.offlineStreams.length == 1) {
                        this.viewData.videoContainer.removeClass('mainStream');
                        this.viewData.secondVideo.removeClass('notVisible');
                        this.viewData.thirdVideo.removeClass('notVisible');
                        this.streamSizeMobile(this.remote_connected);
                        this.viewData.videoContainer.addClass('mainScreenBottom');
                    }
                    break;
            }


        },

        switchStream(newStream) {

            this.closeConnection();

            this.active_stream = newStream;
            this.reconnect();

        },
        touchStart(positions) {

            var smib = this.machine.controlBoard;
            //let mainStream = this.machine.videoStreams.find(e => e.videoStreamType.code == 'Game');
            this.$socket.emit('touch_start', smib, this.session.distributedId, positions);

        },

        sendCommandTest(command) {
            var self = this;
            if (this.keys_blocked) {
                return;
            }
            let code = this.lookupKey(command);
            console.log('button press', code);
            var smib = this.machine.controlBoard;
            this.$socket.emit('send_command', smib, code);
            this.keys_blocked = true;
            setTimeout(function() {
                self.keys_blocked = false;
            }, 300);

        },


        answerOffer: function(broadcaster_socket_id, description) {

            var self = this;

            // Initiate a new WebRTC Peer Connection based on our 
            // config file and the description sent from the broadcaster
            self.peer_connection = new RTCPeerConnection(this.webrtc_config);
            self.peer_connection
                .setRemoteDescription(description)
                .then(() => self.peer_connection.createAnswer())
                .then(sdp => self.peer_connection.setLocalDescription(sdp))
                .then(() => {
                    self.$socket.emit("answer", broadcaster_socket_id, self.peer_connection.localDescription);
                });

            // On receive track from broadcaster
            self.peer_connection.ontrack = event => {
                self.video_div.srcObject = event.streams[0];
                self.$forceUpdate();

                setTimeout(function() {
                    // Reclaculate layout 
                    self.loading = false;
                }, 1000);

            };

            // Emit our watcher candidates to 
            // the broadcaster
            self.peer_connection.onicecandidate = event => {
                if (event.candidate) {


                    if (self.force_turn) {
                        // Only accept turn connections
                        var candidate = event.candidate.candidate;
                        if (candidate.indexOf("relay") < 0) {
                            return;
                        }
                    }

                    //console.log(event.candidate);
                    self.$socket.emit("candidate", broadcaster_socket_id, event.candidate);
                }
            };

            self.peer_connection.onconnectionstatechange = function() {

                self.peer_connection_state = self.peer_connection.connectionState;
                self.$forceUpdate

                // Handle peer disconnect
                if (self.peer_connection_state === 'disconnected') {
                    self.reconnect();
                    self.connected = false;
                }

                if (self.peer_connection_state === 'connected') {
                    self.loading_status = 'Receiving video stream';
                    self.connected = true;

                }

                // console.log('state changed to:' + self.peer_connection.connectionState); 

            }

        },

        reconnect() {

            this.loading = true;
            this.loading_status = 'Connecting to R2A Systems';

            //console.log('Connection lost, attempting to reconnect...');    
            this.$socket.emit('watch', this.active_stream.socketId);

        },

        closeConnection() {

            if (this.peer_connection) {
                this.$socket.emit('stop_watch', this.active_stream.socketId);
                this.peer_connection.close();
                this.connected = false;

                this.loading = true;
                this.loading_status = 'Connecting to R2A Systems';
            }

        },

        async getMachineDetails() {

            try {

                // Get session information from signal
                let res = await this.$http.get('https://signal-ct.cloud.ro/api/sessions/' + this.$route.params.id);
                this.session = res.data;

                console.log('res', res);

                // Get machine information from session 
                var response = await this.$http.get('https://signal-ct.cloud.ro/api/machines/' + res.data.machine.id);
                this.machine = response.data;
                this.active_stream = response.data.streamKeys[0];
                this.streams = response.data.streamKeys;
                this.offlineStreams = this.machine.videoStreams.filter(stream => stream.signalStatus == 'offline');

                this.$root.$emit('setHeaderDetails', {
                    manufacturer: this.machine.manufacturer.name,
                    slotNumber: this.machine.number,
                })

                console.log('machine', this.machine);


            } catch (error) {
                console.log(error);
            }

        }
    }

}
</script>