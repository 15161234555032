<template>
    <div class="component auth-img">
        <div class="image-data" :class="{'cover': cover}" v-if="imageData" v-bind:style="{ 'background-image': 'url(' + imageData + ')' }">
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "AuthImg.scss";
</style>
<script>
import { Buffer } from 'buffer';
export default {
    name: "AuthImg",
    props: ['src', 'cover', 'filename'],
    data() {
        return {
            imageData: null,
        }
    },
    async mounted() {
        await this.getImage(this.src, this.filename);
    },
    methods: {
        async getImage(src, filename) {
            try {
                let localStorageKey = `CACHE_IMG_${filename}`;
                let localStorageValue = localStorage.getItem(localStorageKey);
                if(localStorageValue) {
                    this.imageData = localStorageValue;
                } else {
                    let response = await this.$http({
                        method: 'get',
                        url: src,
                        responseType: 'arraybuffer',
                    });
                    var mimeType = response.headers['content-type'].toLowerCase();
                    var imgBase64 = new Buffer(response.data, 'binary').toString('base64');
                    this.imageData = 'data:' + mimeType + ';base64,' + imgBase64;    
                    localStorage.setItem(localStorageKey, this.imageData);
                }
            } catch (error) {
                console.log('auth image error:', error)
            }
        },
    }
}
</script>