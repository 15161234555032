<template>
    <div class="view slots" data-aos="fade-in">
        <div class="container-fluid">
            <div class="title" data-aos="fade-in" v-if="manufacturer">
                <router-link class="no-link" to="/platform/manufacturers">
                    <div class="back"><i class="bi bi-chevron-left"></i>
                        Back</div>
                </router-link>
                <div class="name">{{ manufacturer.GroupName }}</div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row ">
                <div class="col-sm-12">
                    <!-- <div class="title" data-aos="fade-in" v-if="manufacturer">
                        <div class="name" style="text-align: left; font-size: 18px;">New Layout</div>
                        <hr style="background-color: #fff;">
                    </div> -->
                    <div class="row slots  justify-content-center" v-if="slots" :class="{ 'loading': loading }">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-for="slot in slots" :key="slot.id" >
                            <div class="slot" :class="[slot.signalStatus, {'active': slot.signalStatus == 'reserved' && user.playerId == slot.current_reservation.PlayerId}]" @click="select(slot, 'new')">
                                <div class="name">
                                    <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                                    <div class="text">
                                        Machine #{{ slot.number }}
                                    </div>
                                </div>
                                <div class="image" style="overflow: hidden;">
                                    <auth-img  v-if="slot.image" :filename="slot.image.fileName" :src="getSlotImage(slot.image.fileName)" alt="" style="width: 100%; height: 300px;" />
                                    <div class="placeholder" v-else style="height: 300px;">
                                    </div>
                                </div>
                                <div class="status">{{ slot.signalStatus }} <span v-if="slot.signalStatus == 'reserved' && user.playerId == slot.current_reservation.PlayerId">FOR YOU</span></div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="overlays" style="position: fixed; top: 0; left: 0;">
            <div class="row loadMoney overlay  justify-content-md-center align-content-center" v-if="false">
                <div class="box col-xs-2">
                    <div class="head">
                        <img class="line" src="@/assets/images/backgrounds/line.png" alt="" style="width: 100%;">
                        <div class="text">
                            CURRENT AMOUNT <div class="pull-right">{{ balance | moneyFormat(true) }} RON</div>
                        </div>
                    </div>
                    <div class="row chips justify-content-md-center">
                        <div class="col-xs-2">
                            <div class="chip" @click="start(10)">
                                <div class="image">
                                    <img src="@/assets/images/chips/yellow.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        10
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="start(50)">
                                <div class="image">
                                    <img src="@/assets/images/chips/pink.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        50
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="start(100)">
                                <div class="image">
                                    <img src="@/assets/images/chips/blue.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        100
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip" @click="start(500)">
                                <div class="image">
                                    <img src="@/assets/images/chips/red.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        500
                                    </div>
                                    <div class="currency">
                                        RON
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="chip all" @click="start(balance)">
                                <div class="image">
                                    <img src="@/assets/images/chips/all.png" alt="">
                                </div>
                                <div class="label">
                                    <div class="amount">
                                        ALL
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row cancel">
                        <div class="col">
                            <button @click="selected = null" type="button" class="btn btn-secondary form-control">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import AuthImg from '@/components/AuthImg/AuthImg.vue';
export default {
    name: "ManufacturersView",
    data() {
        return {
            manufacturer: null,
            slots: null,
            data: null,
            selected: null,
            playerAmount: null,
            balance: null,
            loading: false,
            layout: 'old',
            user: null,
        }
    },
    async mounted() {
        await this.getUser();
        await this.getManufactures();
        await this.getSlots();
    },
    components: {
        AuthImg,
    },
    computed: mapGetters(['getPair']),
    sockets: {
        update_broadcasters: function() {
            this.getSlots();
        },
        /*createdPlayerSessionSuccess: function(session_id) {

            if(this.getPair) {
                this.$socket.emit('tvbox_start_session', session_id, this.getPair.device_id);
                this.$router.push('/platform/layout/' + session_id + '?device=Desktop&viewMode=keypad');
                return;
            }
            if(this.layout == 'old') {
                this.$router.push('/platform/session/' + session_id);
            } 
            else if(this.layout == 'new') {
         
                    this.$router.push('/platform/layout/' + session_id + '?device=Desktop&designStyle=GrayMetal');
             
            }
            else if(this.layout == 'sidebet') {
                this.$router.push('/platform/layout/' + session_id + '?device=Desktop&sidebet=true');   
            }

            this.$toasted.success('Session started');

        },*/
        /*createdPlayerSessionError: function(error) {
            this.loading = false;
            let message = `Unable to start session.<br> ${error}`;
            this.$toasted.error(message);
            console.log('createdPlayerSessionError', message);
        },*/
    },
    methods: {
        getSlotImage(filename) {
            return process.env.VUE_APP_API_LOCALSERVER + '/api/v1/unigens/download_picture?fileName=' + filename
        },
        async getUser() {
            try {
                let response = await this.$http.get('application/userDetails');
                this.user = response.data;
            } catch (error) {
                console.log(error);
                //this.$toasted.error('Server error');
            }
        },  
        async getManufactures() {
            let response = await this.$http.get('unigens/get_machine_groups');
            this.manufacturer = response.data.Result.find(e => {
                return (e.GroupId == this.$route.params.id);
            });
        },
        async getSlots() {
            let response = await this.$http.get(process.env.VUE_APP_API_SIGNAL + '/api/manufacturers/' + this.$route.params.id);
            this.slots = await this.parseSlots(response.data);
        },
        async parseSlots(slots) {

            let userTags = this.user.player.tags;

            return slots.filter(e => {
               let machineTags = e.tags;
               return machineTags.some(r => userTags.includes(r));
            });


            return slots;

            // Return as usual if no special users
            /*let specialUsers = ['savoy_demo_1', 'savoy_demo_2', 'casino_demo'];
            if(!specialUsers.includes(this.user.userName)) {
                return slots;
            }
            let specialSlots = [82, 79];
            return slots.filter(e => {
                return (specialSlots.includes(e.id));
            });*/
        },
        async select(item, layout) {
            if (item.signalStatus === 'active') {
                //await this.getBalance();
                this.selected = item;
                this.start();
                this.layout = layout;
                //window.scrollTo(0, 0);
            }

            if(item.signalStatus == 'reserved' && this.user.playerId == item.current_reservation.PlayerId) {
                this.selected = item;
                this.start();
                this.layout = layout;
            }

        },
        async getBalance() {
            let response = await this.$http.get('players/profile');
            this.balance = (response.data.balance.amount / 100).toFixed(2);
        },
        async start() {

            this.loading = true;
            let response = await this.$http.post(process.env.VUE_APP_API_SIGNAL + '/api/sessions/create', {
                machine_id: this.selected.id,
                socket_id: this.$socket.id,
            });
            if (response.data.success) {
                this.$toasted.info(`Starting session on EGM #${this.selected.number}`);
                this.loading = false;
                this.$router.push('/platform/layout/' + response.data.session_id + '?device=Desktop&designStyle=GrayMetal');

            } else {
                let message = `Unable to start session.<br> ${response.data.reason}`;
                this.$toasted.error(message);
                this.loading = false;
            }

            
        }

    }
}
</script>